// ローディング画面
const load = document.querySelector('.p-loading');
const loadFn = function() {
  setTimeout(() => {
    load.classList.add('is-active');
  }, 3500)
}
window.addEventListener('DOMContentLoaded', loadFn);

// スライドショー（ローディングの影響があるので3秒遅延でスタート）
const slideStart = function() {
  setTimeout(() => {
    let swiper = new Swiper(".mvSwiper", {
        loop: true,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">0' + (index + 1) + "</span>";
          },
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        speed: 2000,
    });
  }, 3500)
}
window.addEventListener('DOMContentLoaded', slideStart);

// スクロール時アニメーション
document.addEventListener('DOMContentLoaded', function() {
  scrollObserve('.js-fadein');
  scrollObserve('.js-left');
  scrollObserve('.js-left--main');
  scrollObserve('.js-zoom');
  scrollObserve('.p-loading__icon');
});

function scrollObserve(val) {
  const els = document.querySelectorAll(val);
  const cb = function(entries, observer) {
      entries.forEach(entry => {
          if(entry.isIntersecting) {
              entry.target.classList.add('is-active');
              observer.unobserve(entry.target); //1回で処理を停止
          } else {
              entry.target.classList.remove('is-active');
          }
      });
  }

  const io = new IntersectionObserver(cb);
  els.forEach(el => io.observe(el));
}

let scroll = new SmoothScroll('a[href*="#"]', {
  speedAsDuration:true,
  speed:1000,
  easing:'easeInOutQuint'
});